.popup-send-requests-meeting-room-popup {
  display: block;
  width: 900px;
  min-height: 50%;
  background-color: #FFFFFF;
  border: 0;
  border-radius: 15px;
  margin: auto 0;
  padding: 12px 0;
  z-index: 2;
  overflow: auto;
  position: relative;
  .more-contain {
    margin-top: 20px;
  }
}



.popup-send-requests-meeting-room-popup .close {
  position: absolute !important; 
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #00A9E0;
}

.popup-send-requests-meeting-room-popup .close:hover {
  color: #00A9E0;
}
.popup-send-requests-meeting-room-popup .content {
  max-height: 100%;

}





.popup-send-requests-meeting-room-popup-title {
  font-size: 32px ;
  font-weight: 600 ;
  color: #00A9E0 ;
}


.meeting-room-popupform-body-inner-txt{
  padding: 10px 0;
  font-size: 18px ;
  font-weight: 150 ;
  color: #5b5656 ;
}


.meeting-room-popup-phone-call-div-img-desktop{
width: 70px;
height: 70px;
filter: drop-shadow(0 0 0.75rem rgb(42, 41, 41));
}
.meeting-room-popup-form-btn-style{
display: block !important;
  text-align: center;
  margin: 0 auto;
}





.popup-send-requests-meeting-room-popup {
  display: flex;
  justify-content: start;
  padding: 0;
  .meeting-room-popup-circular-div-img{
    width: 50%;
    border-radius:16px;
   
    }
    .modal-right-block {
      padding: 30px;
      width: 50%;
    }
}


@media only screen and (max-width: 991px) {
  .popup-send-requests-meeting-room-popup {
    width: 90% !important;
  }


}


@media screen and (max-width: 630px) {
  .popup-send-requests-mobile {
    width: 90% !important;
    height: auto !important;
    background-color: #fff;
    border-radius: 16px;
    position: relative;
  }
  .meeting-room-circular-div-img-mobile-sizes {
    height: auto;
    width: 100%;
    border-radius: 16px;
  }
.meeting-room-popup-phone-call-div-img-mobile {
  width: 50px;
  margin: 0 auto;
  text-align: center;
  display: block;
}
.more-contain, .modal-right-block {
  padding: 20px;
}
.more-contain h2 {
  font-size: 15px;
  font-weight: 600;
  color: #00a9e0;
}
.more-contain h2 br {
  display: none;
}
.popup-send-requests-mobile .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  opacity: 9;
}
.more-contain h6 {
  margin: 40px 0;
}
}