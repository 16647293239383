
    .search-page {
        width:100%;
        .space-box{
            display:grid;
            grid-template-columns:repeat(auto-fill,minmax(240px,1fr));
            width:100%;
            grid-gap:1rem;
        }
    
        @media(max-width:576px) {
            .space-box{
                grid-template-columns:repeat(2,1fr);
                grid-gap:.5rem;
            }
        }
    }

