.popup-send-requests-banquet-popup {
  display: block;
  width: 900px;
  min-height: 55%;
  background-color: #FFFFFF;
  border: 0;
  border-radius: 15px;
  margin: auto 0;
  z-index: 2;
  display: flex;
  justify-content: start;
  padding: 0;
  overflow: auto;
  position: relative;


  .banquet-popup-circular-div-img {
    width: 40%;
    border-radius: 16px;

  }
  .modal-right-block {
    padding: 30px 45px;
    width: 60%;

  }
  .banquet-popup-phone-call-div-img-desktop {
    width: 70px;
    height: 70px;
    -webkit-filter: drop-shadow(0 0 0.75rem #2a2929);
    filter: drop-shadow(0 0 0.75rem #2a2929);
  }
  .popup-send-requests-banquet-popup-title {
   
    font-size: 32px !important;
    font-weight: 600 !important;
    color: #00a9e0 !important;
    margin-top: 20px;
}
.banquet-popupform-body-inner-txt {
  padding: 10px 0;
  font-size: 18px !important;
  font-weight: 150 !important;
  color: #5b5656 !important;
}
.close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  color: #00a9e0;
}
}



@media only screen and (min-width: 767px) {
  .popup-send-requests-banquet-popup {
    width: 90%;
  }
  
}

@media only screen and (max-width: 767px) {
  .popup-send-requests-banquet-popup {
    width: 90%;
    flex-direction: column;
    position: relative;
    .banquet-popup-circular-div-img {
      width: 100%;
    }
    .modal-right-block {
      width: 100%;
      padding: 0 0 30px 0;
    }
    .popup-send-requests-banquet-popup-title {
      font-size: 15px !important;
      font-weight: 600 !important;
    }
    .banquet-popupform-body-inner-txt {
      font-size: 11px !important;
      font-weight: 600 !important;
      color: #5b5656 !important;
      margin: 20px 0;
    }
    .banquet-popup-phone-call-div-img-desktop {
      position: absolute;
      top: 290px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    .close {
      color: #fff;
      opacity: 9;
      top: 10px;
      right: 20px;
    }
  }


}