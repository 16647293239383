.popup-send-requests-co-working {
  width: 75%;
  min-height: 70%;
  background-color: #FFFFFF;
  border-radius: 15px;
  display: flex;
  justify-content: start;
  padding: 0;
  overflow: auto;
  position: relative;


  .co-working-popup-circular-div-img {
    width: 100%;
    height: 100%;
    border-radius: 16px;

  }

  .modal-left-block {
    width: 50%;
    position: relative;

  }

  .modal-left-inner {
    position: absolute;
    left: 30px;
    top: 30px;

    .co-working-popup-phone-call-div-img-desktop {

      width: 70px;
      height: 70px;
      -webkit-filter: drop-shadow(0 0 0.75rem #2a2929);
      filter: drop-shadow(0 0 0.75rem #2a2929);
    }

    .float-text-popupco-working-desktop {

      color: #fff;
      font-size: 25px;
      font-weight: 400;
      overflow: hidden;
    }

    .float-inner-text-popupco-working-desktop {

      color: #fff;
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
      font-family: Arial, Helvetica, sans-serif;
      overflow: hidden;
    }
  }

  .modal-right-block {
    padding: 30px 45px;
    width: 50%;

    @media screen and (max-width: 1250px) {

      height: 400px;
      position: relative;
      overflow: auto;
    }

    .co-working-popup-send-requests-title {
      font-size: 32px !important;
      font-weight: 600;
      color: #00a9e0;

      br {
        display: block;
      }
    }

    .co-working-popUpform-body-inner-txt {
      padding: 10px 0;
      font-size: 15px !important;
      font-weight: 100;
      color: #5b5656;
      line-height: 1.5;

      br {
        display: block;
      }
    }

    .close {
      font-size: 30px;
      font-weight: 700;
      text-decoration: none;
      color: #00a9e0;
    }

    form {
      br {
        display: none;
      }
    }
  }

  .popoUp-form-co-working-desktop-size {
    width: 85%;
  }


}

@media only screen and (min-width: 767px) {
  .popup-send-requests-co-working {
    .modal-right-block {
      height: auto;
    }
    .popoUp-form-co-working-desktop-size {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {

  .popup-send-requests-co-working {
    flex-direction: column;

    .modal-left-block,
    .modal-right-block {
      width: 100%;
    }

    .modal-right-block {
      padding: 0;
      position: static;

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #fff;
        opacity: 9;
      }

      .co-working-popUpform-body-inner-txt {
        margin: 20px 0;
      }
    }

    .modal-left-inner {
      height: 100%;
      width: calc(100% - 30px);

      .float-text-popupco-working-desktop {
        color: #fff;
        font-size: 12px !important;
        font-weight: 400;
      }

      .float-inner-text-popupco-working-desktop {
        display: none;
      }

      .co-working-popup-phone-call-div-img-desktop {
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }

    .popoUp-form-co-working-desktop-size {
      width: 100%;
    }
  }

}