.background-photo-AboutUs-page-first-img {
  
  width: 100%;
  background-size: cover;
}
@media screen and (max-width: 800px) {
  .background-photo-AboutUs-page-first-img {
    height: 250px;
    width: 100%;
  }
}
.AboutUs-page-first-image-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .AboutUs-page-first-image-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px !important;
    font-weight: 300 !important;
    color: #ffffff !important;
    justify-content: center;
    width: 100%;
  }
}
.AboutUS-pg-div-first-sec{
  position: relative;
  text-align: center;
}
.second-sec-size-AboutUS-pg{
  width: 100%;
  height: 100%;
  background-color: white;
}
.subtitle-AboutUS-pg-all-secs{
  font-weight: 600 !important;
  padding: 10px 0 !important;
  color: #5b5656 !important;
  font-size: 40px !important;
  line-height: 48px;
}
.AboutUS-pg-body-text{
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px;
  color: #45494d;
  width: 80%;
}
@media screen and (max-width: 500px) {
  .AboutUS-pg-body-text{
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 30px;
    color: #45494d;
    width: 70%;
  }
}
.third-sec-background-AboutUS-pg-01{
  background-image: url('../img/material business/business page june V2-24 - top-right.png');
  background-repeat: no-repeat;
  background-size: 1500px 500px;
}
.third-sec-background-AboutUS-pg-02{
  background-image: url('../img/material business/business page june V2-24 - bottom - right.png');
  background-repeat: no-repeat;
  background-size: 100% 500px;
  justify-content: center;
}
@media screen and (max-width: 1100px) {
  .third-sec-background-AboutUS-pg-02{
    background-image: url('../img/material business/business page june V2-24 - bottom - right.png');
    background-repeat: no-repeat;
    background-size: 150% 100%;
    justify-content: center;
  }
}
.third-sec-background-hero-sec-img-AboutUS-pg{
  background-image: url('../img/images/crumbled paper.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.AboutUS-pg-body-text-third-sec-01{
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px;
  color: #45494d;
  width:75%;

}
@media screen and (max-width: 1200px) {
  .AboutUS-pg-body-text-third-sec-01{
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 30px;
    color: #45494d;
    width:75%;
    
  }
}
.AboutUS-pg-body-text-third-sec-02{
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px;
  color: #45494d;
  width:80%;
}
.AboutUS-pg-body-text-third-sec-03{
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px;
  color: #45494d;
  width:80%;
  
}
.AboutUS-pg-body-text-third-sec-04{
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px;
  color: #45494d;
  width:75%;
}
.fourth-sec-background-AboutUS-pg{
  background-image: url('../img/images/collaboration-with-team.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.fourth-sec-first-img-AboutUs-pg{
  height: 150px;
  width: 120px;
  padding-top: 30px;
}
.fourth-sec-header-AboutUs-pg{
  display: flex;
}
.subtitle-fourth-sec-AboutUS-pg-all-secs{
  font-weight: 600 !important;
  padding: 50px 0 !important;
  color: #5b5656 !important;
  font-size: 40px !important;
  line-height: 48px;
}
@media screen and (max-width: 1200px) {
  .subtitle-fourth-sec-AboutUS-pg-all-secs{
    font-weight: 600 !important;
    padding: 50px 0 !important;
    color: #5b5656 !important;
    font-size: 28px !important;
    line-height: 28px;
  }
}
.hr-style-AboutUS-pg {
  border: 0;
  height: 1px;
  width: 800px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin-top: 100px;
}
.fourth-sec-icons-AboutUs-pg{
  height: 30px;
  width: 30px;
  margin-left: 130px;
}
.icons-subtitle-fourth-sec-AboutUS-pg-01{
  font-weight: 600 !important;
  padding: 50px 0 !important;
  color: #5b5656 !important;
  font-size: 24px !important;
  line-height: 48px;
  margin-top: -60px;
}
@media screen and (max-width: 1200px) {
  .icons-subtitle-fourth-sec-AboutUS-pg-01{
    font-weight: 600 !important;
    padding: 50px 0 !important;
    color: #5b5656 !important;
    font-size: 14px !important;
    line-height: 48px;
    margin-top: -60px;
  }
}
.fourth-sec-icons-text-alignment-AboutUs-pg{
  display: inline-flex;
}
.icons-subtitle-inner-text-fourth-sec-AboutUS-pg{
  font-weight: 50 !important;
  color: #5b5656 !important;
  font-size: 24px !important;
  line-height: 48px;
}
@media screen and (max-width: 1200px) {
  .icons-subtitle-inner-text-fourth-sec-AboutUS-pg{
    font-weight: 500 !important;
    color: #5b5656 !important;
    font-size: 16px !important;
    line-height: 28px;
  } 
}
.icons-subtitle-inner-text-fourth-sec-AboutUS-pg-01-02{
  margin-left: 133px;
  margin-top: -70px;
}
@media screen and (max-width: 1200px) {
  .icons-subtitle-inner-text-fourth-sec-AboutUS-pg-01-02{
    margin-left: 133px;
    margin-top: -68px;
  }
}
.icons-subtitle-inner-text-fourth-sec-AboutUS-pg-03{
  margin-top: -8px;
}
@media screen and (max-width: 1200px) {
  .icons-subtitle-inner-text-fourth-sec-AboutUS-pg-03{
    margin-top: 30px;
    margin-left: -125px;
  }
}
.icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05{
  margin-top: -8px;
}
@media screen and (max-width: 1200px) {
  .icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05{
    margin-top: 30px;
    margin-left: -120px;
  }
}
.icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05{
  font-weight: 500 !important;
  color: #5b5656 !important;
  font-size: 24px !important;
  line-height: 48px;
}
@media screen and (max-width: 1200px) {
  .icons-subtitle-inner-text-fourth-sec-AboutUS-pg-04-05{
    font-weight: 500 !important;
    color: #5b5656 !important;
    font-size: 16px !important;
    line-height: 28px;
  }
}
.sec-4-pos-AboutUs-pg{
  margin-top: -50px;
}
@media screen and (max-width: 1200px) {
  .sec-4-pos-AboutUs-pg{
    margin-top: -50px;
    margin-left: -110px;
  }
}
.sec-3-pos-AboutUs-pg{
  margin-top: -10%;
}
@media screen and (max-width: 800px) {
  .sec-3-pos-AboutUs-pg{
    margin-top: -25%;
  }
}
.sec-3-second-txt-pos-AboutUs-pg{
  margin-top: -1%;
}
@media screen and (max-width: 800px) {
  .sec-3-second-txt-pos-AboutUs-pg{
    margin-top: 1%;
  }
}
.AboutUS-pg-last-sec-body-txt{
  font-weight: 900 !important;
  padding: 50px 0 !important;
  color: #45494D !important;
  font-size: 24px !important;
  line-height: 48px;
  width: 70%;
}
.last-sec-background-AboutUS-pg-02{
  background-image: url('../img/material business/business page june V2-24 - bottom - right.png');
  background-repeat: no-repeat;
  background-size: 100% 500px;
  justify-content: center;
}
@media screen and (max-width: 1100px) {
  .last-sec-background-AboutUS-pg-02{
    background-image: url('../img/material business/business page june V2-24 - bottom - right.png');
    background-repeat: no-repeat;
    background-size: 150% 100%;
    justify-content: center;
  }
}
.last-sec-background-AboutUS-pg-height{
    min-height: calc(100vh - 460px); /*380*/
}