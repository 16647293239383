.payment-form {
	input {
		font-size: 14px !important;
	}

	input {
		margin-bottom: 0 !important;
		border: 0;
		background-color: #FFFFFF;
	}

}
