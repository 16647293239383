.reservation-space-stick {
    display: flex;
    flex-direction: column;
    z-index: 9 !important;
    /*width: 400px;*/
    /*margin-left: 42px;*/
    background-color: #ECF1F7;
    border-radius: 12px;
    padding: 16px 16px 4px;
    position: sticky;
    top: 100px;
    transition: 0.6s
}

@media (max-width: 1199px) {
    .reservation-space-stick {
        /*width: 300px;*/
    }

    .reservation-space-stick {
        /*width: 400px;*/
        max-height: calc(100vh - 155px);
    }
}

@media (max-width: 1039px) {

    .reservation-space-stick {
        /*width: calc(100% - 508px);*/
    }
}

@media (max-width: 991px) {
    .reservation-space-stick {
        /*width: calc(100% - 448px);*/
    }

    .reservation-space-stick {
        display: none;
    }

    .reservation-space-stick {
        display: flex;
    }
}

@media (max-width: 767px) {
    .reserv-page .reservation-space-stick, .bottom-cont .reservation-space-stick {
        /*display: none;*/
    }

    .reservation-space-stick {
        order: 1;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #cfd8e7;
        border-radius: 0;
        /*width: 100%;*/
        /*margin: 0;*/
        padding: 0 0 24px;

    }
}

@media (max-height: 750px) {
    .reservation-space-stick .side-title {
        /*display: none;*/
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}


.reservation-space-stick .bar-btn {
    outline: none;
    border: 0;
    box-shadow: none;
    border-radius: 10px !important;
}

.reservation-space-stick {
    /*width: 500px;*/
}

.reservation-space-stick .space-review {
    font-size: 15px;
    font-weight: 400;
}

.reservation-space-stick .space-book {
    font-size: 15px;
    font-weight: 400;
    padding: 20px 16px 12px;
    text-align: center;
}

.reservation-space-stick .book-divider {
    width: calc(100% - 48px);
    height: 2px;
    border-bottom: 2px solid #cfd8e7 !important;
    margin: 24px 24px 4px !important;
}

.reservation-space-stick {
    background-color: #FFFFFF;
    border: 1px solid #cfd8e7;
    border-radius: 12px;
    padding: 24px;
}

.reservation-space-stick .book-toprow {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 4px 0 12px;
}

.reservation-space-stick .side-title {
    display: block;
}

.reservation-space-stick .book-toprow h4 {
    color: #555555;
}

.reservation-space-stick .term-label {
    position: absolute;
    right: 20px;
    top: 34px;
    font-weight: 700;
    color: #989898;
    font-size: 17px;
}

.reservation-space-stick .book-row {
    width: 100%;
    position: relative;
    overflow-y: auto;
    max-height: 250px;
}

.reservation-space-stick p {
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    color: #41494e;
}

.reservation-space-stick .space-img {
    width: 100px !important;
    height: 80px !important;
    flex: 0 0 80px !important;
    margin-right: 16px;
}

.reservation-space-stick .space-details {
    width: calc(100% - 116px);
    flex: 1 1 calc(100% - 116px);
}

.reservation-space-stick .space-price {
    font-size: 15px !important;
}

.reservation-space-stick .space-price .price {
    /*font-size: 17px !important;*/
}

.content {
    max-width: 80% !important;
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 1400px) {
    .content {
        max-width: 80% !important;
    }
}

@media (max-width: 1200px) {
    .content {
        max-width: 90% !important;
    }
}

@media (max-width: 992px) {
    .content {
        max-width: 95% !important;
    }
}

@media (max-width: 768px) {
    .content {
        max-width: 100% !important;
    }
}

@media (max-width: 576px) {
    .content {
        max-width: 100% !important;
    }
}








